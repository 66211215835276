import {
  Col,
  Row
} from 'react-bootstrap';
import PlaceHolderShimmer from '../PlaceHolderShimmer';

const QuoteBoxLoading = ({ enableEarthquakeCoverage }) => (
  <div>
    <h3 className='card-heading'>Loading Quote...</h3>
      <Row>
        <Col xs={6}>
          <PlaceHolderShimmer h="12px" w="110px" mb="5px" mt="4px" />
          <PlaceHolderShimmer h="22px" w="68px" mb="12px" />
        </Col>
        <Col xs={6}>
          <PlaceHolderShimmer h="12px" w="100px" mb="5px" mt="4px" />
          <PlaceHolderShimmer h="22px" w="68px" mb="12px" />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <PlaceHolderShimmer h="12px" w="65px" mb="5px" mt="0px" />
          <PlaceHolderShimmer h="22px" w="40px" mb="22px" />
        </Col>
          {enableEarthquakeCoverage === true && (
        <Col xs={6}>
          <PlaceHolderShimmer h="12px" w="126px" mb="5px" mt="0px" />
          <PlaceHolderShimmer h="22px" w="124px" mb="22px" />
        </Col>
        )}
      </Row>
      <Row>
        <Col className='d-flex justify-content-center' >
        <PlaceHolderShimmer h="52px" w="124px" mb="22px" />
        </Col>
      </Row>
  </div>
);

export default QuoteBoxLoading;
