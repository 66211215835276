
import styled from 'styled-components';


const PlaceHolderShimmer = styled.div`
    height: ${props => props.h ? `${props.h}` : '0.75rem' };
    width: ${props => props.w ? `${props.w}` : '100%' };
    margin-top: ${props => props.mt ? `${props.mt}` : '0' };
    margin-bottom: ${props => props.mb ? `${props.mb}` : '0' };
    background-color: #ECECEC;
    border-radius: 6px;
    opacity: .5;
    background: linear-gradient(100deg, #D4D5D6, #ECECEC, #D4D5D6, #ECECEC );
    background-size: 400% 400%;
    -webkit-animation: gradientBG 3s ease infinite;
         animation: gradientBG 3s ease infinite;
`

export default PlaceHolderShimmer;
