import { useCallback, useState } from "react";

import { getSchool as getSchoolAPI } from '../api/getSchool';

const useSchool = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState();
    const [enableEarthquakeCoverage, setEnableEarthquakeCoverage] = useState(false);
    const [earthquakeCoverage, setEarthquakeCoverage] = useState(false);

    const getSchool = useCallback(async (schoolID, onFail) => {
      setLoading(true);
      const response = await getSchoolAPI(schoolID);
      if (response.status === 200) {
        setData(response.body.data);
        const isCA = response.body.data.address_state === 'CA';
        setEnableEarthquakeCoverage(isCA);
        setEarthquakeCoverage(isCA);
      } else {
        setError(response.message);
      }
      setLoading(false);
    },[])

    return {
      getSchool,
      data,
      error,
      loading,
      enableEarthquakeCoverage,
      earthquakeCoverage,
      setEarthquakeCoverage,
    };
}

export default useSchool;
