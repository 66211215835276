// eslint-disable-next-line import/named
import { PropertiesActionTypes } from './properties.types';

// eslint-disable-next-line import/prefer-default-export
export const setStates = states => {
  return {
    type: PropertiesActionTypes.SET_STATES,
    payload: states
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setSelectedState = state => {
  return {
    type: PropertiesActionTypes.SET_SELECTED_STATE,
    payload: state
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setSelectedProperty = property => {
  return {
    type: PropertiesActionTypes.SET_SELECTED_PROPERTY,
    payload: property
  };
};


