import styled from 'styled-components';


const PopOutPanel = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  flex-basis: 0;
  flex-grow: 1;
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.15);
  -webkit-box-shadow: 0 0 16px 0 rgba(0,0,0,0.15);
  -moz-box-shadow: 0 0 16px 0 rgba(0,0,0,0.15);
`

export default PopOutPanel;
