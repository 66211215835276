import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeDecline } from './API';
import {
  Alert,
  Button,
  Col,
  Collapse,
  Container,
  Form,
  Row,
  Spinner
} from 'react-bootstrap';
import DeclineSuccess from './DeclineSuccess';
import Wrapper from './Wrapper';
import Logo from './Logo';
import "./theme/theme.css"
import Label from './Label';
import {
  Link,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import Errors from './Errors';

const schema = yup.object({
  firstName: yup.string().trim().required().label('First name'),
  lastName: yup.string().trim().required().label('Last name'),
  email: yup.string().trim().email().required().label('Email'),
  declineReason: yup
    .string().nullable()
    .oneOf(['have_coverage', 'homeowners_coverage', 'decline_coverage'], 'You must select a reason for declining.')
    .required('You must select a reason for declining.'),
  insuranceProvider: yup.string().when(['declineReason'], (declineReason) => {
    if (declineReason === 'have_coverage') {
      return yup.string().trim().required().label('Insurance Provider');
    }
  })
}).required();

const Decline = ({
  property
}) => {
  const { params } = useRouteMatch();
  const schoolID = params.schoolID; // optional
  const [declineSuccess, setDeclineSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const declineReason = watch('declineReason');

  const onSubmit = async (values) => {
    setIsLoading(true);
    const response = await makeDecline(
      values.firstName,
      values.lastName,
      values.email,
      property?.id,
      schoolID,
      values.declineReason,
      values.insuranceProvider
    );

    if (response.status === 200 || response.status === 201) {
      setDeclineSuccess(true);
    } else {
      setError(response.body);
    }
    setIsLoading(false);
  };

  if (!property && !schoolID) {
    if (schoolID) {
      return (
        <Redirect to={`/schools/${schoolID}/billing-info`} />
      );
    } else {
      return (
        <Redirect to='/purchase-flow/billing-info' />
      );
    }
  }

  if (declineSuccess === true) {
    return (
      <DeclineSuccess />
    );
  }

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
      <Container className="styledContainer">
        <Row>
          <Col xs={12} md={12}>
            <Logo/>
            <h1 className='card-heading'>Please tell us how you&rsquo;ll be protecting your belongings this school year.</h1>
          </Col>
        </Row>
        <Container style={{maxWidth: 500}}>
          {error && (
            <Alert variant='danger'>
              <Errors errors={error} />
            </Alert>
          )}
          <Row>
            <Col xs={12} md={12}>
              <Form.Group>
                <Label>
                  Email&#x2a;
                  <Form.Control
                    className={errors.email && 'is-invalid'}
                    {...register("email", { required: true, type: 'email' })}
                  />
                  {errors.email && <span className="error">{errors.email.message}</span>}
                </Label>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group>
                <Label>
                  First Name&#x2a;
                  <Form.Control
                    className={errors.firstName && 'is-invalid'}
                    {...register("firstName", { required: true })}
                  />
                  {errors.firstName && <span className="error">{errors.firstName.message}</span>}
                </Label>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Label>
                  Last Name&#x2a;
                  <Form.Control
                    className={errors.lastName && 'is-invalid'}
                    {...register("lastName", { required: true })}
                  />
                  {errors.lastName && <span className="error">{errors.lastName.message}</span>}
                </Label>
              </Form.Group>
            </Col>
            <Col xs={12} md={12}>
              {errors.declineReason && (
                <Alert variant='danger'>
                  {errors.declineReason && <span className="error">{errors.declineReason.message}</span>}
                </Alert>
              )}
              <Form.Group>
                <Form.Check
                  type='radio'
                  id='have_coverage'
                  value='have_coverage'
                  label='I already have insurance coverage.'
                  {...register("declineReason", { required: true })}
                />
              </Form.Group>
              <Collapse in={declineReason === 'have_coverage'}>
                <Form.Group>
                  <Label>
                    <Form.Control
                      placeholder="Insurance Provider"
                      className={errors.insuranceProvider && 'is-invalid'}
                      {...register("insuranceProvider", { required: true })}
                    />
                    {errors.insuranceProvider && <span className="error">{errors.insuranceProvider.message}</span>}
                  </Label>
                </Form.Group>
              </Collapse>
              <Form.Group>
                <Form.Check
                  type='radio'
                  id='homeowners_coverage'
                  value='homeowners_coverage'
                  label='I am covered under my parent&rsquo;s homeowners policy.'
                  {...register("declineReason", { required: true })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type='radio'
                  id='decline_coverage'
                  value='decline_coverage'
                  label='I plan to be financially responsible for my actions and valuables.'
                  {...register("declineReason", { required: true })}
                />
              </Form.Group>
            </Col>
          </Row>
          <span><small> &#x2a; indicates a required field</small></span>
        </Container>
      </Container>
      <Container>
        <Row className='justify-content-center text-center'>
          <Col>
            <Button type='submit' variant='primary' disabled={isLoading}>
              {isLoading ? <Spinner as="span" animation="border" role="status" aria-hidden="true" /> : 'I Decline'}
            </Button>
          </Col>
        </Row>
        <Row className='justify-content-center mb-2'>
          <Col className='text-center pt-3'>
              <Link to={schoolID ? `/schools/${schoolID}/quote` : '/purchase-flow/quote'} className='text-secondary'>
                I changed my mind
              </Link>
          </Col>
        </Row>
      </Container>
      </form>
    </Wrapper>
  );
}

Decline.propTypes = {
  property: PropTypes.object
};

const mapStateToProps = state => ({
  property: state.properties.selectedProperty
});

export default connect(mapStateToProps)(Decline);
