// eslint-disable-next-line import/named
import { QuoteActionTypes } from './quote.types';

// eslint-disable-next-line import/prefer-default-export
export const setEnableEarthquakeCoverage = enableEarthQuakeCoverage => {
  return {
    type: QuoteActionTypes.SET_ENABLE_EARTHQUAKE_COVERAGE,
    payload: enableEarthQuakeCoverage
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setEarthquakeCoverage = earthquakeCoverage => {
  return {
    type: QuoteActionTypes.SET_EARTHQUAKE_COVERAGE,
    payload: earthquakeCoverage
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setQuote = quote => {
  return {
    type: QuoteActionTypes.SET_QUOTE,
    payload: quote
  };
};
