import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import React from 'react'
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import GlobalContext from './hooks/global/GlobalContext';
import useGlobal from './hooks/global/useGlobal';
import BillingInfo from './components/BillingInfo';
import Decline from './components/Decline';
import PropertySelector from './components/PropertySelector';
import Purchase from './components/Purchase';
import Quote from './components/Quote';
import ResidentInfo from './components/ResidentInfo';

Bugsnag.start({
  apiKey: '8c0e3e865c88d142ab34b08d8e712bfa',
  plugins: [new BugsnagPluginReact()],
  autoTrackSessions: false,
  logger: null,
  releaseStage: process.env.REACT_APP_RELEASE_STAGE,
  enabledReleaseStages: [
    'quality_assurance',
    'user_acceptance_testing',
    'production',
  ],
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
// Modal.setAppElement('#root');

function App() {
  const global = useGlobal();
  return (
    <ErrorBoundary>
      <GlobalContext.Provider value={global}>
        <Router>
          <Switch>
            <Route exact path='/purchase-flow'>
              <PropertySelector />
            </Route>
            <Route exact path='/purchase-flow/quote'>
              <Quote />
            </Route>
            <Route exact path='/purchase-flow/resident-info'>
              <ResidentInfo />
            </Route>
            <Route exact path='/purchase-flow/billing-info'>
              <BillingInfo />
            </Route>
            <Route exact path='/purchase-flow/purchase'>
              <Purchase />
            </Route>
            <Route exact path='/purchase-flow/decline'>
              <Decline />
            </Route>

            {/* Quote flow */}
            <Route exact path='/schools/:schoolID/quote'>
              <Quote />
            </Route>
            <Route exact path='/schools/:schoolID/resident-info'>
              <ResidentInfo />
            </Route>
            <Route exact path='/schools/:schoolID/billing-info'>
              <BillingInfo />
            </Route>
            <Route exact path='/schools/:schoolID/purchase'>
              <Purchase />
            </Route>
            <Route exact path='/schools/:schoolID/decline'>
              <Decline />
            </Route>
            <Route path='*'>
              <>Not Found</>
            </Route>
          </Switch>
        </Router>
        <div className="disclaimer">
          Coverage is provided by Markel American Insurance Company and/or Markel Insurance Company
          and underwritten by Markel Service, Incorporated, national producer license # 27585,
          in California d/b/a Markel Insurance Services, license # 0645481. Policyholder services are
          provided by Next Generation Insurance Group, LLC. Insurance and coverage are subject to
          availability and qualifications and may not be available in all states. Terms and conditions
          for rates and coverages varies. All insurance products are governed by the terms, conditions,
          limitations, and exclusions set forth in the applicable insurance policy. Please see a copy of
          your policy for the full terms, conditions, and exclusions. Any information on the site does
          not in any way alter, supplement, or amend the terms, conditions, limitations, or exclusions
          of the applicable insurance policy and is intended only as a brief summary of such insurance products.
        </div>
      </GlobalContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
