import { useMemo, useState} from 'react';
import { connect } from 'react-redux';
import {
  setUnitNumber,
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setOptIn
} from '../redux/residentInfo/residentInfo.actions';
import {
  useRouteMatch,
  useHistory,
  Redirect,
} from 'react-router-dom';
import ResidentInfoForm from  './ResidentInfoForm';
import { useGlobalContext } from '../hooks/global/GlobalContext';
import useSchoolQuote from '../hooks/useSchoolQuote';
import PurchaseSuccess from './PurchaseSuccess';
import ResidentInfoQuote from './ResidentInfoQuote';
import Wrapper from './Wrapper';

const ResidenInfo = ({
  setUnitNumber,
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setOptIn,
  quote,
  residentInfo,
  policy,
}) => {
  const {
    implementation: {
      startDate,
      setStartDate,
      coverage,
      minStartDate,
      maxStartDate,
    },
    school: {
      earthquakeCoverage,
      enableEarthquakeCoverage,
      setEarthquakeCoverage,
    }
  } = useGlobalContext();
  const { getQuote: getSchoolQuote, loading: quoteLoading } = useSchoolQuote();
  const { params } = useRouteMatch();
  const history = useHistory();
  const schoolID = params.schoolID; // optional
  const [showNewQuote, setShowNewQuote] = useState(false);
  const [newQuote, setNewQuote] = useState();
  const [newStartDate, setNewStartDate] = useState('');
  const [valuesForm, setValuesForm] = useState({});
  const displayNewQuote = useMemo(() => showNewQuote && newQuote, [newQuote, showNewQuote]);

  const onSaveForm = (values) => {
    setUnitNumber(values.unitNumber);
    setFirstName(values.firstName);
    setLastName(values.lastName);
    setEmail(values.email);
    setPhone(values.phone);
    setOptIn(values.optIn);
    setStartDate(values.startDate);
    if (schoolID) {
      history.push(`/schools/${schoolID}/billing-info`);
    } else {
      history.push("/purchase-flow/billing-info");
    }
  };

  const onSubmit = async (values) => {
    if (schoolID && values.startDate !== startDate) {
      const response = await getSchoolQuote(schoolID, earthquakeCoverage, coverage, startDate);
      if (response.body.data.price !== quote.price) {
        setValuesForm(values);
        setNewStartDate(values.startDate);
        setNewQuote(response.body.data);
        setShowNewQuote(true);
      } else {
        onSaveForm(values);
      }
    } else {
      onSaveForm(values);
    }
  };

  if (quote === null) {
    if (schoolID) {
      return (
        <Redirect to={`/schools/${schoolID}/quote`} />
      );
    } else {
      return (
        <Redirect to='/purchase-flow' />
      );
    }
  }

  if(policy) {
    return (
      <PurchaseSuccess
        policy={policy}
      />
    );
  }

  return <Wrapper>
    {displayNewQuote &&
      <ResidentInfoQuote
        quote={quote}
        newQuote={newQuote}
        enableEarthquakeCoverage={enableEarthquakeCoverage}
        earthquakeCoverage={earthquakeCoverage}
        setEarthquakeCoverage={setEarthquakeCoverage}
        startDate={startDate}
        newStartDate={newStartDate}
        onContinue={() => onSaveForm(valuesForm)}
        onCancel={() => setShowNewQuote(false)}
      />
    }
    <div className={displayNewQuote ? 'hidden' : ''}>
      <ResidentInfoForm
        startDate={startDate}
        residentInfo={residentInfo}
        onSubmit={onSubmit}
        schoolID={schoolID}
        loading={quoteLoading}
        minStartDate={minStartDate}
        maxStartDate={maxStartDate}
      />
      </div>
  </Wrapper>
};


const mapStateToProps = state => ({
  unitNumber: state.residentInfo.unitNumber,
  firstName: state.residentInfo.firstName,
  lastName: state.residentInfo.lastName,
  email: state.residentInfo.email,
  phone: state.residentInfo.phone,
  optIn: state.residentInfo.optIn,
  quote: state.quote.quote,
  residentInfo: state.residentInfo,
  policy: state.policy.policy,
});

const mapDispatchToProps = dispatch => ({
  setUnitNumber: unitNumber => dispatch(setUnitNumber(unitNumber)),
  setFirstName: firstName => dispatch(setFirstName(firstName)),
  setLastName: lastName => dispatch(setLastName(lastName)),
  setEmail: email => dispatch(setEmail(email)),
  setPhone: phone => dispatch(setPhone(phone)),
  setOptIn: optIn => dispatch(setOptIn(optIn))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResidenInfo);