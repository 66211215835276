// eslint-disable-next-line import/named
import { BillingInfoActionTypes } from './billingInfo.types';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  country: 'US',
  creditCardNumber: '',
  cvc: '',
  expMonth: '',
  expYear: ''
};

const billingInfoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BillingInfoActionTypes.SET_BILLING_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload
      };
    case BillingInfoActionTypes.SET_BILLING_LAST_NAME:
      return {
        ...state,
        lastName: action.payload
      };
    case BillingInfoActionTypes.SET_BILLING_PHONE:
      return {
        ...state,
        phone: action.payload
      };
    case BillingInfoActionTypes.SET_BILLING_EMAIL:
      return {
        ...state,
        email: action.payload
      };
    case BillingInfoActionTypes.SET_BILLING_ADDRESS_LINE_1:
      return {
        ...state,
        addressLine1: action.payload
      };
    case BillingInfoActionTypes.SET_BILLING_ADDRESS_LINE_2:
      return {
        ...state,
        addressLine2: action.payload
      };
    case BillingInfoActionTypes.SET_BILLING_CITY:
      return {
        ...state,
        city: action.payload
      };
    case BillingInfoActionTypes.SET_BILLING_STATE:
      return {
        ...state,
        state: action.payload
      };
    case BillingInfoActionTypes.SET_BILLING_ZIP:
      return {
        ...state,
        zip: action.payload
      };
    case BillingInfoActionTypes.SET_BILLING_COUNTRY:
      return {
        ...state,
        country: action.payload
      };
    case BillingInfoActionTypes.SET_BILLING_CREDIT_CARD_NUMBER:
      return {
        ...state,
        creditCardNumber: action.payload
      };
    case BillingInfoActionTypes.SET_BILLING_CVC:
      return {
        ...state,
        cvc: action.payload
      };
    case BillingInfoActionTypes.SET_BILLING_EXP_MONTH:
      return {
        ...state,
        expMonth: action.payload
      };
    case BillingInfoActionTypes.SET_BILLING_EXP_YEAR:
      return {
        ...state,
        expYear: action.payload
      };
    default:
      return state;
  }
};

export default billingInfoReducer;
