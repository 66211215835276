import styled from 'styled-components';

const Label = styled.label`
  font-size: ${props => props.report ? "0.75rem" : "0.875rem"} ;
  color: #727272;
  text-align: left;
  margin-bottom: ${props => props.report ? "0px" : "2px"};
  display: ${props => props.inline ? "inline-block" : "block"};
  color: ${props => props.light ? "#727272" : "#333"};
`

export default Label;
