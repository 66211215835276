// eslint-disable-next-line import/named
import { AgreementsActionTypes } from './agreements.types';

const INITIAL_STATE = {
  termsAndConditions: false,
  addressIndicated: false
};

const agreementsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AgreementsActionTypes.SET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndConditions: action.payload
      };
    case AgreementsActionTypes.SET_ADDRESS_INDICATED:
      return {
        ...state,
        addressIndicated: action.payload
      };
    default:
      return state;
  }
};

export default agreementsReducer;
