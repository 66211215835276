import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getStatesProperties } from './API';
import {
  setStates,
  setSelectedState,
  setSelectedProperty
} from '../redux/properties/properties.actions';
import {
  setEnableEarthquakeCoverage,
  setQuote
} from '../redux/quote/quote.actions';
import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  Row,
  Container
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CollegeRentersInsurance from './CollegeRentersInsurance';
import Label from './Label'
import Wrapper from './Wrapper';
import Logo from './Logo';
import PlaceHolderShimmer from './PlaceHolderShimmer';
import "./theme/theme.css"

const getID = (key) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const propertyID = params.get(key);
  return propertyID;
}

const getStateProperty = (states, key, id) => {
  for (let i = 0; i < states.length; i++) {
    const state = states[i];
    for (let j = 0; j < state.properties.length; j++) {
      const property = state.properties[j];
      // eslint-disable-next-line eqeqeq
      if (property[key] == id) {
        return {
          state,
          property,
        };
      }
    }
  }
  return null;
};

const getStatePropertyBase = (states) => {
  const id = getID('property_id');
  if (id) {
    return getStateProperty(states, 'id', id);
  } else {
    const id = getID('external_id');
    if (id) {
      return getStateProperty(states, 'external_id', id);
    }
  }
  return null;
}


const PropertySelector = ({
  states,
  setStates,
  selectedState,
  setSelectedState,
  selectedProperty,
  setSelectedProperty,
  setEnableEarthquakeCoverage,
  setQuote
}) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [showValidation, setShowValidation] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
      const getStates = async () => {
        const response = await getStatesProperties();
        if (response.status === 200) {
          setStates(response.body);
          const data = getStatePropertyBase(response.body);
          if (data) {
            setSelectedState(data.state);
            setSelectedProperty(data.property);
            history.push("/purchase-flow/quote");
          }
        } else {
          setError(response.message);
        }
      };

      if (states === null) {
        getStates()
      }
    },
    []
  );

  const selectState = (e) => {
    const state = states.find(state => state.abbreviation === e.target.value);
    setSelectedState(state);
    setSelectedProperty(null);
  };

  const selectProperty = (e) => {
    /* eslint-disable eqeqeq */
    const property = selectedState.properties.find(property => property.id == e.target.value);
    setSelectedProperty(property);
    setEnableEarthquakeCoverage(property.address_state === 'CA');
    setQuote(null);
  };

  const isValid = (fieldName) => {
    switch (fieldName) {
      case 'state':
        return selectedState !== null;
      case 'property':
        return selectedProperty !== null;
      default:
        return selectedState !== null && selectedProperty !== null;
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (isValid()) {
      history.push("/purchase-flow/quote");
    } else {
      setShowValidation(true)
    }
  }

  return (
    <Wrapper>
      <form onSubmit={onSubmit}>
        <Container className="styledContainer">
          <Logo/>
          <Row>
            <Col xs={12} md={6}>
              <CollegeRentersInsurance />
            </Col>
            <Col xs={12} md={6}>
              {states === null && error === null && (
                <Form.Group>
                  <PlaceHolderShimmer h="14px" w="35px" mb="5px" mt="6px" />
                  <PlaceHolderShimmer h="40px" mb="22px" />
                  <PlaceHolderShimmer h="14px" w="58px" mb="5px" />
                  <PlaceHolderShimmer h="40px" mb="36px" />
                  <PlaceHolderShimmer h="48px" />
                </Form.Group>
              )}
              {error !== null && (
                <Alert variant='danger'>{error}</Alert>
              )}
              {states !== null && (
                <>
                  <Form.Group>
                    <Label light>State</Label>
                    <Form.Control
                      as='select'
                      onChange={selectState}
                      value={selectedState !== null ? selectedState.abbreviation : ''}
                      className={showValidation &&  !isValid('state') && 'is-invalid'}
                    >
                      <option disabled />
                      {states.map(state => (
                        <option
                          key={state.abbreviation}
                          value={state.abbreviation}
                        >
                          {state.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Label light>Property</Label>
                    <Form.Control
                      as='select'
                      onChange={selectProperty}
                      value={selectedProperty !== null ? selectedProperty.id : ''}
                      className={showValidation &&  !isValid('property') && 'is-invalid'}
                    >
                      <option disabled />
                      {selectedState !== null && selectedState.properties.map(property => (
                        <option key={property.id} value={property.id}>{property.property_name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className='text-center'>
                    <Button type='submit'>
                      Get a Quote
                    </Button>
                  </Form.Group>
                </>
              )}

            </Col>
          </Row>
        </Container>
      </form>
    </Wrapper>
  );
}

PropertySelector.propTypes = {
  states: PropTypes.array,
  setStates: PropTypes.func.isRequired,
  selectedState: PropTypes.object,
  setSelectedState: PropTypes.func.isRequired,
  selectedProperty: PropTypes.object,
  setSelectedProperty: PropTypes.func.isRequired,
  setEnableEarthquakeCoverage: PropTypes.func.isRequired,
  setQuote: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  states: state.properties.states,
  selectedState: state.properties.selectedState,
  selectedProperty: state.properties.selectedProperty
});

const mapDispatchToProps = dispatch => ({
  setStates: states => dispatch(setStates(states)),
  setSelectedState: state => dispatch(setSelectedState(state)),
  setSelectedProperty: property => dispatch(setSelectedProperty(property)),
  setEnableEarthquakeCoverage: enableEarthquakeCoverage => dispatch(setEnableEarthquakeCoverage(enableEarthquakeCoverage)),
  setQuote: quote => dispatch(setQuote(quote))
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertySelector);
