import {
  Col,
  Form,
  Row
} from 'react-bootstrap';
import Label from '../Label'
import Term from '../Term';
import { formatPrice } from '../../resources/utils';
import TotalPrice from '../TotalPrice';

const QuoteBox = ({
  setShowModal,
  quote,
  enableEarthquakeCoverage,
  disableEarthquakeCoverage,
  earthquakeCoverage,
  setEarthquakeCoverage,
}) => {
  const handleEarthquakeCoverage = (e) => {
    setEarthquakeCoverage(e.target.checked);
  }

  return (
    <div>
      <button
        className="buton-as-link quote-modal-link  float-right"
        onClick={setShowModal}
        onKeyPress={setShowModal}
      >
        See what&rsquo;s covered
      </button>
      <h3 className='card-heading'>Recommended</h3>
      <Row className='mb-2'>
        <Col>
          <Label light report>Property Coverage</Label>
            <p className="value">{ formatPrice(quote.property_limit) }</p>
        </Col>
        <Col>
          <Label light report>Liability Coverage</Label>
          <p className="value">{ formatPrice(quote.liability_limit) }</p>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          <Label light report>Deductible</Label>
          <p className="value">{ formatPrice(quote.deductible) }</p>
        </Col>
        <Col>
          {enableEarthquakeCoverage === true && (
            <>
              <Label light report>Earthquake Coverage</Label>
              <Row>
                <Col xs={8}>
                  <p className="value">
                    { formatPrice(quote.earthquake_price) }
                    <small style={{fontSize: '60%'}}>/<Term /></small>
                  </p>
                </Col>
                <Col xs={4} className='p-0'>
                  <Form.Check
                    id='earthquake-switch'
                    type='switch'
                    disabled={disableEarthquakeCoverage}
                    checked={earthquakeCoverage}
                    onChange={handleEarthquakeCoverage}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <TotalPrice />
        </Col>
      </Row>
    </div>
  );
};

export default QuoteBox;
