import { useState } from 'react';
import {
  Button,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import Modal from 'react-modal';
import WhatIsCovered from './WhatIsCovered';
import QuoteBox from './ui/QuoteBox';
import Logo from './Logo';
import QuoteNewPriceMessage from './ui/QuoteNewPriceMessage';
import PopOutPanel from './PopOutPanel';

const ResidentInfoQuote = ({
  quote,
  newQuote,
  enableEarthquakeCoverage,
  earthquakeCoverage,
  setEarthquakeCoverage,
  startDate,
  newStartDate,
  onContinue,
  onCancel,
}) => {
  const [showModal, setShowModal] = useState(false);
  return <div>
    <Container className="styledContainer">
      <Row>
        <Col>
          <Logo/>
        </Col>
      </Row>
      <Row>
        <Col>
          <QuoteNewPriceMessage
            startDate={startDate}
            newStartDate={newStartDate}
            price={quote.price}
            newPrice={newQuote.price}
          />
        </Col>
        <Col>
          <PopOutPanel>
            <QuoteBox
              quote={newQuote}
              setShowModal={setShowModal}
              enableEarthquakeCoverage={enableEarthquakeCoverage}
              earthquakeCoverage={earthquakeCoverage}
              setEarthquakeCoverage={setEarthquakeCoverage}
              disableEarthquakeCoverage={true}
            />
          </PopOutPanel>
          <Row className='pb-2 justify-content-center'>
            <Col className='text-center'>
              <Button onClick={onContinue}>
                Continue
              </Button>
            </Col>
          </Row>
          <Row className='pb-2 justify-content-center'>
            <Col className='pt-0 text-center'>
              <Button variant="link" onClick={onCancel}>
                Select a new start date
              </Button>
            </Col>
          </Row>
          <Modal
              isOpen={showModal}
              onRequestClose={() => setShowModal(false)}
              ariaHideApp={false}
              shouldFocusAfterRender={true}
              shouldCloseOnEsc={true}
              style={{ content: {} }}
            >
              <button type="button" className="close" onClick={() => setShowModal(false)} onKeyPress={() => setShowModal(false)}>
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
            <WhatIsCovered onClose={() => setShowModal(false)} />
          </Modal>
        </Col>
      </Row>
    </Container>
  </div>;
}

export default ResidentInfoQuote;
