import { combineReducers } from 'redux';
import agreementsReducer from './agreements/agreements.reducer';
import billingInfoReducer from './billingInfo/billingInfo.reducer';
import propertiesReducer from './properties/properties.reducer';
import quoteReducer from './quote/quote.reducer';
import residentInfoReducer from './residentInfo/residentInfo.reducer';
import policyReducer from './policy/policy.reducer';

export default combineReducers({
  agreements: agreementsReducer,
  billingInfo: billingInfoReducer,
  properties: propertiesReducer,
  quote: quoteReducer,
  residentInfo: residentInfoReducer,
  policy: policyReducer,
});
