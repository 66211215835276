import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { formatPrice } from '../resources/utils';
import Term from './Term';

const TotalPrice = ({
  enableEarthquakeCoverage,
  earthquakeCoverage,
  quote
}) => {
  const totalPrice = enableEarthquakeCoverage === false ? quote.price :
    earthquakeCoverage === true ? quote.price : quote.price - quote.earthquake_price
  ;

  return (
    <div className='text-primary text-center'>
      <div className='h1 price mb-0'>{ formatPrice(totalPrice) }</div>
      <div className="price-term">/<Term /></div>
    </div>
  );
}

TotalPrice.propTypes = {
  enableEarthquakeCoverage: PropTypes.bool.isRequired,
  earthquakeCoverage: PropTypes.bool.isRequired,
  quote: PropTypes.object
};

const mapStateToProps = state => ({
  enableEarthquakeCoverage: state.quote.enableEarthquakeCoverage,
  earthquakeCoverage: state.quote.earthquakeCoverage,
  quote: state.quote.quote
});

export default connect(mapStateToProps)(TotalPrice);
