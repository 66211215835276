// eslint-disable-next-line import/named
import { ResidentInfoActionTypes } from './residentInfo.types';

const sumDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};
const getStringDate = (date) => date.toISOString().split('T')[0];
const tomorrow = () => getStringDate(sumDays(new Date(), 1));

const INITIAL_STATE = {
  unitNumber: '',
  startDate: tomorrow(),
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  optIn: true
};

const residentInfoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ResidentInfoActionTypes.SET_RESIDENT_UNIT_NUMBER:
      return {
        ...state,
        unitNumber: action.payload
      };
    case ResidentInfoActionTypes.SET_RESIDENT_START_DATE:
      return {
        ...state,
        startDate: action.payload
      };
    case ResidentInfoActionTypes.SET_RESIDENT_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload
      };
    case ResidentInfoActionTypes.SET_RESIDENT_LAST_NAME:
      return {
        ...state,
        lastName: action.payload
      };
    case ResidentInfoActionTypes.SET_RESIDENT_EMAIL:
      return {
        ...state,
        email: action.payload
      };
    case ResidentInfoActionTypes.SET_RESIDENT_PHONE:
      return {
        ...state,
        phone: action.payload
      };
    case ResidentInfoActionTypes.SET_RESIDENT_OPT_IN:
      return {
        ...state,
        optIn: action.payload
      };
    default:
      return state;
  }
};

export default residentInfoReducer;
