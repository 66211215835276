import { useForm, Controller } from "react-hook-form";
import InputMask from 'react-input-mask';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setFirstName,
  setLastName,
  setPhone,
  setEmail,
  setCountry,
  setAddressLine1,
  setAddressLine2,
  setCity,
  setState,
  setZip,
  setCreditCardNumber,
  setCvc,
  setExpMonth,
  setExpYear
} from '../redux/billingInfo/billingInfo.actions';
import {
  Button,
  Col,
  Container,
  Form,
  Row
} from 'react-bootstrap';
import Wrapper from './Wrapper';
import Logo from './Logo';
import {
  Link,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import Label from './Label';
import { countries } from '../resources/countries';
import { expMonths } from '../resources/expMonths';
import stateAbbreviations from '../resources/stateAbbreviations';
import PurchaseSuccess from './PurchaseSuccess';

const expYears = Array.from(Array(11).keys());
const currentYear = (new Date()).getFullYear();

const schema = yup.object({
  firstName: yup.string().trim().required().label('First name'),
  lastName: yup.string().trim().required().label('Last name'),
  email: yup.string().trim().email().required().label('Email'),
  phone: yup.string().trim().max(15).min(10).required().label('Phone'),

  country: yup.string().trim().min(2, 'Min 2 chars.').required('Billing country is required'),
  addressLine1: yup.string().trim().min(2, 'Billing address is required').required('Billing address is required'),
  addressLine2: yup.string(),
  city: yup.string().trim().min(2, 'Billing city is required').required('Billing city is required'),
  state: yup.string().trim().required('Billing state is required'),
  zip: yup.string().trim().min(5, 'Billing ZIP is required').required('Billing zip is required'),

  creditCardNumber: yup.string().required('Card number is required'),
  expMonth: yup.string().required('Month is required'),
  expYear: yup.string().required('Year is required'),
  cvc: yup.string().min(3, 'CVC is required').required('CVC is required'),
}).required();

const BillingInfo = ({
  billingInfo,
  setFirstName,
  setLastName,
  setPhone,
  setEmail,
  setCountry,
  setAddressLine1,
  setAddressLine2,
  setCity,
  setState,
  setZip,
  setCreditCardNumber,
  setCvc,
  setExpMonth,
  setExpYear,
  resident,
  policy,
  }) => {
  const { params } = useRouteMatch();
  const schoolID = params.schoolID; // optional
  const history = useHistory();

  const { register, handleSubmit, watch, control, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: billingInfo,
  });

  const country = watch('country')

  if (!resident.firstName.trim() || !resident.lastName.trim()) {
    if (schoolID) {
      return (
        <Redirect to={`/schools/${schoolID}/quote`} />
      );
    } else {
      return (
        <Redirect to='/purchase-flow' />
      );
    }
  }

  if(policy) {
    return (
      <PurchaseSuccess
        policy={policy}
      />
    );
  }

  const onSubmit = (values) => {
    setFirstName(values.firstName);
    setLastName(values.lastName);
    setPhone(values.phone);
    setEmail(values.email);
    setCountry(values.country);
    setAddressLine1(values.addressLine1);
    setAddressLine2(values.addressLine2);
    setCity(values.city);
    setState(values.state);
    setZip(values.zip);
    setCreditCardNumber(values.creditCardNumber);
    setCvc(values.cvc);
    setExpMonth(values.expMonth);
    setExpYear(values.expYear);
    if (schoolID) {
      history.push(`/schools/${schoolID}/purchase`);
    } else {
      history.push("/purchase-flow/purchase");
    }
  }

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container className="styledContainer">
          <Row>
            <Col>
              <Logo/>
            </Col>
          </Row>
          <Container style={{maxWidth: '600px' }}>
            <Row className='justify-content-center'>
              <Col className="text-center">
                <h3 className='card-heading'>Billing Information</h3>
              </Col>
            </Row>
            <Row className='justify-content-center mb-2'>
              <Col className="p-2" sm={4}>
                <Label>
                  First Name on Card&#x2a;
                  <Form.Control
                    className={errors.firstName && 'is-invalid'}
                    {...register("firstName", { required: true })}
                  />
                  {errors.firstName && <span className="error">{errors.firstName.message}</span>}
                </Label>
              </Col>
              <Col className="p-2" sm={4}>
                <Label>
                    Last Name on Card&#x2a;
                  <Form.Control
                    className={errors.lastName && 'is-invalid'}
                    {...register("lastName", { required: true })}
                  />
                  {errors.lastName && <span className="error">{errors.lastName.message}</span>}
                </Label>
              </Col>
              <Col className="p-2" sm={4}>
                <Label>
                  Billing Phone&#x2a;
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <InputMask
                        mask={country === 'US' ? '999 999 9999' : '999 999 9999 999'}
                        maskChar={null}
                        value={field.value}
                        onChange={field.onChange}
                      >
                        {
                          (inputProps) => (
                            <Form.Control
                              className={errors.phone && 'is-invalid'}
                              label="Phone"
                              type="text"
                              ref={field.ref}
                              {...inputProps}
                            />
                          )
                        }
                      </InputMask>
                    )}
                  />
                  {errors.phone && <span className="error">{errors.phone.message}</span>}
                </Label>
              </Col>
            </Row>
            <Row className='justify-content-center mb-2'>
              <Col className="p-2" sm={6}>
                <Label>
                    Billing Email&#x2a;
                  <Form.Control
                    className={errors.email && 'is-invalid'}
                    {...register("email", { required: true, type: 'email' })}
                  />
                  {errors.email && <span className="error">{errors.email.message}</span>}
                </Label>
              </Col>
              <Col className="p-2" sm={6}>
                <Label>
                  Country&#x2a;
                  <Form.Control
                    as='select'
                    className={errors.country && 'is-invalid'}
                    {...register("country", { required: true })}
                  >
                    {Object.keys(countries).map(key => (
                      <option key={key} value={key}>{countries[key]}</option>
                    ))}
                  </Form.Control>
                  {errors.country && <span className="error">{errors.country.message}</span>}
                </Label>
              </Col>
            </Row>
            <Row className='justify-content-center mb-2'>
              <Col xs={12} md={6} className="p-2">
                <Form.Group>
                  <Label>
                    Address Line 1&#x2a;
                    <Form.Control
                      className={errors.addressLine1 && 'is-invalid'}
                      {...register("addressLine1", { required: true })}
                    />
                    {errors.addressLine1 && <span className="error">{errors.addressLine1.message}</span>}
                  </Label>
                </Form.Group>
                <Form.Group>
                  <Label>
                      Address Line 2
                    <Form.Control
                      className={errors.addressLine2 && 'is-invalid'}
                      {...register("addressLine2")}
                    />
                    {errors.addressLine2 && <span className="error">{errors.addressLine2.message}</span>}
                  </Label>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="p-2">
                <Form.Group>
                  <Label>
                    City&#x2a;
                    <Form.Control
                      className={errors.city && 'is-invalid'}
                      {...register("city", { required: true })}
                    />
                    {errors.city && <span className="error">{errors.city.message}</span>}
                  </Label>
                </Form.Group>
                <Form.Row>
                  <Col>
                    <Label>
                      {country === 'US' ? 'State' : 'Province'}&#x2a;
                      {country === 'US' && (
                        <Form.Control
                          as='select'
                          className={errors.state && 'is-invalid'}
                          {...register("state", { required: true })}
                        >
                          <option disabled />
                          {Object.keys(stateAbbreviations).map(abbreviation => (
                            <option key={abbreviation} value={abbreviation}>{stateAbbreviations[abbreviation]}</option>
                          ))}
                        </Form.Control>
                      )}
                      {country !== 'US' && (
                        <Form.Control
                          className={errors.state && 'is-invalid'}
                          {...register("state", { required: true })}
                        />
                      )}
                      {errors.state && <span className="error">{errors.state.message}</span>}
                    </Label>
                  </Col>
                  <Col>
                    <Label>
                      {country === 'US' ? 'Zip' : 'Postal'} Code*
                      <Form.Control
                        className={errors.zip && 'is-invalid'}
                        {...register("zip", { required: true })}
                      />
                      {errors.zip && <span className="error">{errors.zip.message}</span>}
                    </Label>
                  </Col>
                </Form.Row>
              </Col>
            </Row>
            <Row className='justify-content-center mb-2'>
              <Col className="p-2" sm={4}>
                <Label>
                  Credit Card Number&#x2a;
                  <Controller
                    name="creditCardNumber"
                    control={control}
                    render={({ field }) => (
                      <InputMask
                        mask="9999 9999 9999 9999 9"
                        maskChar={null}
                        value={field.value}
                        onChange={field.onChange}
                      >
                        {
                          (inputProps) => (
                            <Form.Control
                              className={errors.creditCardNumber && 'is-invalid'}
                              ref={field.ref}
                              {...inputProps}
                            />
                          )
                        }
                      </InputMask>
                    )}
                  />
                  {errors.creditCardNumber && <span className="error">{errors.creditCardNumber.message}</span>}
                </Label>
              </Col>
              <Col className="p-2" sm={2}>
                <Label>
                  CVC&#x2a;
                  <Controller
                    name="cvc"
                    control={control}
                    render={({ field }) => (
                      <InputMask
                        mask="9999"
                        maskChar={null}
                        value={field.value}
                        onChange={field.onChange}
                      >
                        {
                          (inputProps) => (
                            <Form.Control
                              className={errors.cvc && 'is-invalid'}
                              ref={field.ref}
                              {...inputProps}
                            />
                          )
                        }
                      </InputMask>
                    )}
                  />
                  {errors.cvc && <span className="error">{errors.cvc.message}</span>}
                </Label>
              </Col>
              <Col className="p-2" sm={4}>
                <Label>
                  Exp. Month&#x2a;
                  <Form.Control
                    as='select'
                    className={errors.expMonth && 'is-invalid'}
                    {...register("expMonth", { required: true })}
                  >
                    <option disabled />
                    {expMonths.map(month => (
                      <option key={month.value} value={month.value}>{month.name}</option>
                    ))}
                  </Form.Control>
                  {errors.expMonth && <span className="error">{errors.expMonth.message}</span>}
                </Label>
              </Col>
              <Col className="p-2" sm={2}>
                <Label>
                  Exp. Year&#x2a;
                  <Form.Control
                    as='select'
                    className={errors.expYear && 'is-invalid'}
                    {...register("expYear", { required: true })}
                  >
                    <option disabled />
                    {expYears.map(sum => (
                      <option key={currentYear + sum} value={currentYear + sum}>{currentYear + sum}</option>
                    ))}
                  </Form.Control>
                  {errors.expYear && <span className="error">{errors.expYear.message}</span>}
                </Label>
              </Col>
            </Row>
            <span><small>&#x2a; indicates a required field</small></span>
          </Container>
        </Container>
        <Container>
          <Row className='justify-content-center mb-2'>
            <Col className='text-center'>
              <Button type='submit' variant='primary'>Continue</Button>
            </Col>
          </Row>
          <Row className='justify-content-center mb-2'>
            <Col className='text-center pt-3'>
              <Link to={schoolID ? `/schools/${schoolID}/resident-info` : '/purchase-flow/resident-info'} className='text-secondary'>
                Return to Previous Screen
              </Link>
            </Col>
          </Row>
        </Container>
      </form>
    </Wrapper>
  );
}

BillingInfo.propTypes = {
  billingInfo: PropTypes.object.isRequired,
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setCountry: PropTypes.func.isRequired,
  setAddressLine1: PropTypes.func.isRequired,
  setAddressLine2: PropTypes.func.isRequired,
  setCity: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  setZip: PropTypes.func.isRequired,
  setCreditCardNumber: PropTypes.func.isRequired,
  setCvc: PropTypes.func.isRequired,
  setExpMonth: PropTypes.func.isRequired,
  setExpYear: PropTypes.func.isRequired,
  resident: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  billingInfo: state.billingInfo,
  resident: state.residentInfo,
  property: state.properties.selectedProperty,
  policy: state.policy.policy,
});

const mapDispatchToProps = dispatch => ({
  setFirstName: firstName => dispatch(setFirstName(firstName)),
  setLastName: lastName => dispatch(setLastName(lastName)),
  setPhone: phone => dispatch(setPhone(phone)),
  setEmail: email => dispatch(setEmail(email)),
  setCountry: country => dispatch(setCountry(country)),
  setAddressLine1: addressLine1 => dispatch(setAddressLine1(addressLine1)),
  setAddressLine2: addressLine2 => dispatch(setAddressLine2(addressLine2)),
  setCity: city => dispatch(setCity(city)),
  setState: state => dispatch(setState(state)),
  setZip: zip => dispatch(setZip(zip)),
  setCreditCardNumber: creditCardNumber => dispatch(setCreditCardNumber(creditCardNumber)),
  setCvc: cvc => dispatch(setCvc(cvc)),
  setExpMonth: expMonth => dispatch(setExpMonth(expMonth)),
  setExpYear: expYear => dispatch(setExpYear(expYear))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
