// eslint-disable-next-line import/named
import { AgreementsActionTypes } from './agreements.types';

// eslint-disable-next-line import/prefer-default-export
export const setTermsAndConditions = termsAndConditions => {
  return {
    type: AgreementsActionTypes.SET_TERMS_AND_CONDITIONS,
    payload: termsAndConditions
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setAddressIndicated = addressIndicated => {
  return {
    type: AgreementsActionTypes.SET_ADDRESS_INDICATED,
    payload: addressIndicated
  };
};
