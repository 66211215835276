import styled from 'styled-components';
import logo from '../assets/logo.svg';


export default styled.div`
  height: 40px;
  width: 150px;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 10px;
`;
