import { useCallback, useState } from "react";

import { getSchoolQuote as getSchoolQuoteAPI } from '../api/getSchoolQuote';

const useSchoolQuote = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState();

    const getQuote = useCallback(async (
      schoolID, earthquakeCoverage, coverage, startDate,
    ) => {
      setLoading(true);
      const response = await getSchoolQuoteAPI(schoolID, earthquakeCoverage, coverage, startDate);
      if (response.status === 200) {
        setData(response.body.data);
      } else {
        setError(response.message);
      }
      setLoading(false);
      return response;
    }, [])

    return {
      getQuote,
      data,
      error,
      loading,
    };
}

export default useSchoolQuote;
