// eslint-disable-next-line import/named
import { QuoteActionTypes } from './quote.types';

const INITIAL_STATE = {
  enableEarthquakeCoverage: false,
  earthquakeCoverage: true,
  quote: null
};

const quoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QuoteActionTypes.SET_ENABLE_EARTHQUAKE_COVERAGE:
      return {
        ...state,
        enableEarthquakeCoverage: action.payload
      };
    case QuoteActionTypes.SET_EARTHQUAKE_COVERAGE:
      return {
        ...state,
        earthquakeCoverage: action.payload
      };
    case QuoteActionTypes.SET_QUOTE:
      return {
        ...state,
        quote: action.payload
      };
    default:
      return state;
  }
};

export default quoteReducer;
