import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makePurchase } from './API';
import { useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner
} from 'react-bootstrap';
import Modal from 'react-modal';
import TermsConditions from './TermsConditions';
import {
  Link,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import Wrapper from './Wrapper';
import Logo from './Logo';
import PopOutPanel from './PopOutPanel';
import Label from './Label';
import PurchaseSuccess from './PurchaseSuccess';
import Term from './Term';
import TotalPrice from './TotalPrice';
import { formatPrice } from '../resources/utils';
import {
  setTermsAndConditions,
  setAddressIndicated,
} from '../redux/agreements/agreements.actions';
import { setOptIn } from '../redux/residentInfo/residentInfo.actions';
import { setPolicy } from '../redux/policy/policy.actions';
import Errors from './Errors';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useGlobalContext } from '../hooks/global/GlobalContext';

dayjs.extend(advancedFormat)
const isBML = (startDate, days = 90) => {
  const todayPlus90Days = dayjs().add(days, 'day');
  return startDate.isAfter(todayPlus90Days);
};

/**
 *
 * @param dayjs. DayJS startDate
 * @param number paymentInterval
 * @returns
 */
const getNextPaymentDate = (startDate, paymentInterval) => {
  if (isBML(startDate)) {
    return startDate.subtract(15, 'day');
  }
  const newDate = startDate.add(paymentInterval, 'month');
  return newDate.subtract(15, 'day');
};

const Address = ({property}) => {
  if (property.school_name) {
    return (
      <span>
        Residential housing as assigned by and on record with: {property.school_name}
      </span>
    )
  }
  return (
    <span>
      {property.property_name}
      <br />
      {property.address1}<br />
      {property.address2 && (
        <>
          {property.address2}<br />
        </>
      )}
      {property.address_city}, {property.address_state} {property.address_zip}
    </span>
  );
}

const getSchema = (smsRequired) => yup.object({
  addressIndicated: yup.boolean()
    .required('The "understanding" must be accepted')
    .oneOf([true], 'The "understanding" must be accepted.'),
  termsAndConditions: yup.boolean()
    .required('The terms and conditions must be accepted')
    .oneOf([true], 'The terms and conditions must be accepted.'),
  monthly: yup.boolean()
  .required('The monthly conditions must be accepted')
  .oneOf([true], 'The monthly conditions must be accepted.'),
  sms: yup.boolean().when({
    is: () => smsRequired,
    then: () => yup.boolean().required('Text message conditions must be accepted')
      .oneOf([true], 'Text message conditions must be accepted.')
  }),
});

const Purchase = ({
  billingInfo,
  property,
  quote,
  residentInfo,
  setTermsAndConditions,
  setAddressIndicated,
  setOptIn,
  policy,
  setPolicy,
}) => {
  const {
    school: {
      data: school,
      earthquakeCoverage,
    },
    implementation,
  } = useGlobalContext();

  const { params } = useRouteMatch();
  const schoolID = params.schoolID; // optional
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const lastFour = billingInfo.creditCardNumber.slice(-4);
  const asterisks = billingInfo.creditCardNumber.slice(0, -4).replace(/[0-9]/g, '*');

  const startDate = useMemo(() => dayjs(implementation.startDate), [implementation.startDate]);
  const startDateFormated = useMemo(() => startDate.format('MM/DD/YYYY'), [startDate]);
  const nextPaymentDate = useMemo(() => getNextPaymentDate(startDate, 1).format('MMMM DD, YYYY'), [startDate]);
  const nextPaymentDay = useMemo(() => getNextPaymentDate(startDate, 1).format('Do'), [startDate]);

  const itIsBML = useMemo(() => isBML(startDate), [startDate]);
  const schema = useMemo(() => getSchema(itIsBML), [itIsBML]);

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      addressIndicated: false,
      termsAndConditions: false,
      monthly: false,
      sms: false,
    },
  });

  const onSubmit = async () => {
    setTermsAndConditions(true);
    setAddressIndicated(true);
    setError(null);
    setIsLoading(true);
    if (itIsBML) {
      setOptIn(true);
    }
    const response = await makePurchase(
      billingInfo,
      school ?? property,
      residentInfo,
      quote,
      !!schoolID,
      itIsBML,
      earthquakeCoverage,
      startDate.format('YYYY-MM-DD')
    );
    switch (response.status) {
      case 200:
      case 201:
        if (response.body.error) {
          setError(response.body.error);
        } else {
          setPolicy(response.body.data);
        }
        break;
      case 422:
      case 400:
        setError(response.body);
        break;
      default:
        setError(response.message);
        break;
    }
    setIsLoading(false);
  };

  if (policy !== null) {
    return (
      <PurchaseSuccess
        policy={policy}
        property={property}
      />
    );
  }

  if (billingInfo.creditCardNumber.trim() === '') {
    if (schoolID) {
      return (
        <Redirect to={`/schools/${schoolID}/billing-info`} />
      );
    } else {
      return (
        <Redirect to='/purchase-flow/billing-info' />
      );
    }
  }

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
      <Container className="styledContainer">
        <PopOutPanel>
          <Row>
            <Col md="auto">
              <Row>
                <Col>
                  <Row>
                    <Col md="auto">
                      <Logo style={{maxWidth: '140px'}}/>
                      <h3 className='card-heading mb-1'>Insurance Coverage</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                        <Row>
                            <Col md="auto">
                            <Label report >Property Coverage</Label>
                            <p className="value">{formatPrice(quote.quote.property_limit)}</p>
                          </Col>
                          <Col md="auto">
                            <Label report>Liability Coverage</Label>
                            <p className="value">{formatPrice(quote.quote.liability_limit)}</p>
                          </Col>
                          <Col md="auto">
                            <Label report>Deductible</Label>
                            <p className="value">{formatPrice(quote.quote.deductible)}</p>
                          </Col>
                          {earthquakeCoverage === true && (
                            <Col md="auto">
                              <Label report>Earthquake Insurance</Label>
                              {quote.earthquakeCoverage === true && (
                                <p className="value">
                                  {formatPrice(quote.quote.earthquake_price)}<small>/<Term /></small>
                                </p>
                              )}
                              { quote.earthquakeCoverage === false && (
                                <>
                                  Declined
                                </>
                              )}
                            </Col>
                          )}
                        </Row>
                    </Col>
                  </Row>

                </Col>
              </Row>
            </Col>
            <Col style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              <TotalPrice />
            </Col>
          </Row>
        </PopOutPanel>
        {error !== null && (
          <Row className='mt-4'>
            <Col>
              <Alert variant='danger'>
                <Errors errors={error} />
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="px-5 py-3">
            {!isLoading &&
              <Link
                className='float-right btn-edit'
                to={schoolID ? `/schools/${schoolID}/resident-info` : '/purchase-flow/resident-info'}
              >
                Edit
              </Link>
            }
            <div className='mb-3'>
              <Label light>Coverage Start Date</Label>
              {startDateFormated}
            </div>
            <div className='mb-3'>
              <Label light>First &amp; Last Name</Label>
              {residentInfo.firstName} {residentInfo.lastName}
            </div>
            <div className='mb-3'>
              <Label light>Email</Label>
              {residentInfo.email}
            </div>
            <div className='mb-3'>
              <Label light>Contact Number</Label>
              {residentInfo.phone}
            </div>
          </Col>
          <Col className="px-5 py-3">
            {(!isLoading && !schoolID) &&
              <Link
                className='float-right btn-edit'
                to='/purchase-flow'
              >
                Edit
              </Link>
            }
            <div className='mb-3'>
              <Label light>
                { schoolID ? 'Insured Address': 'Property Address' }
              </Label>
              <Address property={school ?? property} />
            </div>
            <div className='mb-3'>
              {!isLoading &&
                <Link
                  className='float-right btn-edit'
                  to={schoolID ? `/schools/${schoolID}/billing-info` :'/purchase-flow/billing-info'}
                >
                  Edit
                </Link>
              }
              <Label light>Billing Information</Label>
              {asterisks}{lastFour} <small>exp. {billingInfo.expYear}</small>
            </div>
            <div className='mb-3'>
              {!isLoading &&
                <Link
                className='float-right btn-edit'
                to={schoolID ? `/schools/${schoolID}/billing-info` :'/purchase-flow/billing-info'}
              >
                Edit
              </Link>
              }
              <Label light>Billing Address</Label>
              {billingInfo.addressLine1}<br />
              {billingInfo.addressLine2 && (
                <>
                  {billingInfo.addressLine2}<br />
                </>
              )}
              {billingInfo.city && (<>{billingInfo.city}, </> )}
              {billingInfo.state && (<>{billingInfo.state} </>)}
              {billingInfo.country} {billingInfo.zip}
            </div>
          </Col>
        </Row>
        <Row xs={1} md={2}>
          <Col xs={6} className="px-5 mb-2">
            <Form.Check>
              <Label
                light
                inline
                className={errors.monthly && 'text-danger'}
              >
                {errors.monthly && <div className="error mb-1">{errors.monthly.message}</div>}
                <Form.Check.Input
                  className={errors.monthly && 'is-invalid'}
                  {...register("monthly", { required: true })}
                />
                I understand that by selecting to pay monthly, coverage will remain in effect until
                I choose to end my policy term. My next payment will be due on <b>{nextPaymentDate}</b> and
                on the <b>{nextPaymentDay}</b> of each subsequent month.
              </Label>
            </Form.Check>
          </Col>
          <Col xs={6} className="px-5 mb-2">
            <Form.Check>
              <Label
                light
                inline
                className={errors.addressIndicated && 'text-danger'}
              >
                {errors.addressIndicated && <div className="error mb-1">{errors.addressIndicated.message}</div>}
                <Form.Check.Input
                  className={errors.addressIndicated && 'is-invalid'}
                  {...register("addressIndicated", { required: true })}
                />
                I understand that this policy and coverage is valid only at the address indicated at the time of enrollment.
                Don&rsquo;t worry, we can update your policy if you move - just let us know.
              </Label>
            </Form.Check>
          </Col>
          <Col xs={6} className="px-5 mb-2">
            <Form.Check>
              <Label
                light
                inline
                className={errors.termsAndConditions && 'text-danger'}
              >
                {errors.termsAndConditions && <div className="error mb-1">{errors.termsAndConditions.message}</div>}
                <Form.Check.Input
                  className={errors.termsAndConditions && 'is-invalid'}
                  {...register("termsAndConditions", { required: true })}
                />
                I have read and understand the&nbsp;
                <button className="buton-as-link" type="button" onClick={() => setShowModal(true)}>
                  terms and conditions
                </button>&nbsp;for this purchase.
              </Label>
            </Form.Check>
          </Col>
          {itIsBML && (
            <Col xs={6} className="px-5 mb-2">
              <Form.Check>
                <Label
                  light
                  inline
                  className={errors.sms && 'text-danger'}
                >
                  {errors.sms && <div className="error mb-1">{errors.sms.message}</div>}
                  <Form.Check.Input
                    className={errors.sms && 'is-invalid'}
                    {...register("sms", { required: true })}
                  />
                  Your payment is months away and sometimes plans changes. GradGuard will send the cardholder one text
                  message a reminder closer to your payment date. We promise that you will not receive any marketing
                  or promotional text from us.
                </Label>
              </Form.Check>
            </Col>
          )}
        </Row>
        <Row>

        </Row>
        </Container>
        <Container>
          <Row className='justify-content-center mb-2'>
            <Col className='text-center'>
              <Button
                variant='primary'
                type='submit'
                disabled={isLoading}
                >
                  {isLoading
                    ? <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                    : 'Purchase Now'
                  }
                </Button>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          ariaHideApp={false}
          shouldFocusAfterRender={true}
          shouldCloseOnEsc={true}
          style={{ content: {} }}
        >
          <TermsConditions onClose={() => setShowModal(false)} />
        </Modal>
      </form>
    </Wrapper>
  );
}

Purchase.propTypes = {
  billingInfo: PropTypes.object.isRequired,
  quote: PropTypes.object.isRequired,
  residentInfo: PropTypes.object.isRequired,
  termsAndConditions: PropTypes.bool.isRequired,
  setTermsAndConditions: PropTypes.func.isRequired,
  addressIndicated: PropTypes.bool.isRequired,
  setAddressIndicated: PropTypes.func.isRequired,
  setOptIn: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  billingInfo: state.billingInfo,
  property: state.properties.selectedProperty,
  quote: state.quote,
  residentInfo: state.residentInfo,
  termsAndConditions: state.agreements.termsAndConditions,
  addressIndicated: state.agreements.addressIndicated,
  startDate: state.residentInfo.startDate,
  policy: state.policy.policy,
});

const mapDispatchToProps = dispatch => ({
  setTermsAndConditions: termsAndConditions => dispatch(setTermsAndConditions(termsAndConditions)),
  setAddressIndicated: addressIndicated => dispatch(setAddressIndicated(addressIndicated)),
  setOptIn: optIn => dispatch(setOptIn(optIn)),
  setPolicy: policy => dispatch(setPolicy(policy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);
