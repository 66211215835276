import axios from 'axios';
import headers from './headers';
import { ajax } from '../components/API';

export const getSchoolQuote = (id, enableEarthquakeCoverage, coverage, startDate) => {
  const baseData = `?school_id=${id}&preferred=true&earthquake_coverage=${enableEarthquakeCoverage}`;
  const limitData = `liability_coverage=${coverage.liability}&property_coverage=${coverage.property}&deductible=${coverage.deductible}&start_data=${startDate}`;
  return ajax(
    () => axios(
      `${process.env.REACT_APP_GRAD_GUARD_API_BASE_URL}/renters/quote${baseData}&${limitData}`,
      { headers }
    )
  );
}

export const getCurrentRentersImplementationBySchoolID = (schoolID) => {
  return ajax(
    () => axios(
      `${process.env.REACT_APP_GRAD_GUARD_API_BASE_URL}/schools/${schoolID}/currentRentersImplementation`,
      { headers }
    )
  );
}

export const getCurrentRentersImplementationByRefCodeID = (refCodeID) => {
  return ajax(
    () => axios(
      `${process.env.REACT_APP_GRAD_GUARD_API_BASE_URL}/refcodes/${refCodeID}/currentRentersImplementation`,
      { headers }
    )
  );
}
