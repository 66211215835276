import styled from 'styled-components';


export default styled.section`
  padding: 20px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column
`;
