// eslint-disable-next-line import/named
import { PolicyActionTypes } from './policy.types';

const INITIAL_STATE = {
  policy: null,
};

const policyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PolicyActionTypes.SET_POLICY:
      return {
        ...state,
        policy: action.payload
      };
    default:
      return state;
  }
};

export default policyReducer;
