// eslint-disable-next-line import/named
import { PolicyActionTypes } from './policy.types';

// eslint-disable-next-line import/prefer-default-export
export const setPolicy = policy => {
  return {
    type: PolicyActionTypes.SET_POLICY,
    payload: policy,
  };
};
