import axios from 'axios';
import { ajax } from '../components/API';
import headers from './headers';

export const getSchool = (id) => {
  return ajax(() => axios(
    `${process.env.REACT_APP_GRAD_GUARD_API_BASE_URL}/schools/${id}`,
    { headers }
  ));
}
