// eslint-disable-next-line import/named
import { PropertiesActionTypes } from './properties.types';

const INITIAL_STATE = {
  states: null,
  selectedState: null,
  selectedProperty: null
};

const propertiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PropertiesActionTypes.SET_STATES:
      return {
        ...state,
        states: action.payload
      };
    case PropertiesActionTypes.SET_SELECTED_STATE:
      return {
        ...state,
        selectedState: action.payload
      };
    case PropertiesActionTypes.SET_SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: action.payload
      };
    default:
      return state;
  }
};

export default propertiesReducer;
