// eslint-disable-next-line import/named
import { ResidentInfoActionTypes } from './residentInfo.types';

// eslint-disable-next-line import/prefer-default-export
export const setUnitNumber = unitNumber => {
  return {
    type: ResidentInfoActionTypes.SET_RESIDENT_UNIT_NUMBER,
    payload: unitNumber
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setStartDate = startDate => {
  return {
    type: ResidentInfoActionTypes.SET_RESIDENT_START_DATE,
    payload: startDate
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setFirstName = firstName => {
  return {
    type: ResidentInfoActionTypes.SET_RESIDENT_FIRST_NAME,
    payload: firstName
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setLastName = lastName => {
  return {
    type: ResidentInfoActionTypes.SET_RESIDENT_LAST_NAME,
    payload: lastName
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setEmail = email => {
  return {
    type: ResidentInfoActionTypes.SET_RESIDENT_EMAIL,
    payload: email
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setPhone = phone => {
  return {
    type: ResidentInfoActionTypes.SET_RESIDENT_PHONE,
    payload: phone
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setOptIn = optIn => {
  return {
    type: ResidentInfoActionTypes.SET_RESIDENT_OPT_IN,
    payload: optIn
  };
};


