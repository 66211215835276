import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Term = ({
  quote
}) => {
  const term = quote.term === 12 ? 'year' : 'month';

  return (
    <>{term}</>
  )
}

Term.propTypes = {
  quote: PropTypes.object
};

const mapStateToProps = state => ({
  quote: state.quote.quote
});

export default connect(mapStateToProps)(Term);
