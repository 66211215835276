// eslint-disable-next-line import/prefer-default-export
export const BillingInfoActionTypes = {
  SET_BILLING_FIRST_NAME: 'SET_BILLING_FIRST_NAME',
  SET_BILLING_LAST_NAME: 'SET_BILLING_LAST_NAME',
  SET_BILLING_PHONE: 'SET_BILLING_PHONE',
  SET_BILLING_EMAIL: 'SET_BILLING_EMAIL',
  SET_BILLING_COUNTRY: 'SET_BILLING_COUNTRY',
  SET_BILLING_ADDRESS_LINE_1: 'SET_BILLING_ADDRESS_LINE_1',
  SET_BILLING_ADDRESS_LINE_2: 'SET_BILLING_ADDRESS_LINE_2',
  SET_BILLING_CITY: 'SET_BILLING_CITY',
  SET_BILLING_STATE: 'SET_BILLING_STATE',
  SET_BILLING_ZIP: 'SET_BILLING_ZIP',
  SET_BILLING_CREDIT_CARD_NUMBER: 'SET_BILLING_CREDIT_CARD_NUMBER',
  SET_BILLING_CVC: 'SET_BILLING_CVC',
  SET_BILLING_EXP_MONTH: 'SET_BILLING_EXP_MONTH',
  SET_BILLING_EXP_YEAR: 'SET_BILLING_EXP_YEAR'
};
