import { useEffect, useRef } from 'react';
import {
  Button,
  Modal,
} from 'react-bootstrap';
import Logo from './Logo';

const TermsConditions = ({
  onClose,
}) => {
    const button = useRef();
    useEffect(()=>{
      // hack for modal -temporal
      if (button.current) {
        setTimeout(() => {button.current.focus()}, 1)
      }
    }, [])
    return (
      <div id="TermsConditions">
        <button ref={button} autoFocus={true} type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <Modal.Title className="modal-header">
          <div className="modalTitle">
          <Logo style={{maxWidth: '140px'}}/>
            <h3 className='card-heading mb-1'> Terms &amp; Conditions</h3>
          </div>
        </Modal.Title>
        <Modal.Body>
          <h3>Terms and Conditions of Renters Insurance</h3>
          <h4>Service Policies</h4>
          <p><strong>Florida and Connecticut Cancellation Policy:</strong> Policies run on annual terms. After the policy start date, any unused portion of the premium will be refunded on a monthly pro-rata basis. If cancellation is received prior to the policy start date, then all premium collected for that term will be refunded in full.</p>
          <p><strong>All Other States Cancellation Policy:</strong> Policies run on monthly policy terms. Once a policy month term has begun, premium for that month is considered fully earned and not eligible for refund. If premiums are collected prior to the coverage start date, they will be refunded in full.</p>
          <p><strong>Insured's Address Policy:</strong> Policies are issued to a specific address. If your address changes, your policy may need to be modified to ensure coverage. Failing to update your policy address may result in some coverages being unavailable. To update your policy address you must speak directly with a customer service representative by calling GradGuard Customer Service.</p>
          <p><strong>Renewal Policy:</strong> Your policy may be automatically renewed by GradGuard upon its scheduled expiration. You will receive notification prior to this renewal and have the opportunity to decline your renewal, make changes to your insured address or coverage limits, or cancel your renewal after it has been issued.</p>
          <p><strong>Rate Change Policy:</strong> Insurance rates are highly regulated by state, and at times insurance rates are changed. If a rate change occurs between your date of purchase and your coverage start date, GradGuard may credit or charge the difference to your credit card on file. You will be notified by email from GradGuard prior to this transaction.</p>
          <p><strong>Payment &amp; Cancellation Policy:</strong> All scheduled premium payments will be processed automatically on the credit card on file by GradGuard. If a payment transaction is declined, GradGuard will notify you by email. If payment is not received, GradGuard will cancel your insurance policy and will notify you by email of this action.</p>
          <p><strong>Service Fees:</strong> Every time we charge your card there is a $1 installment fee that needs to be taken. This $1 is automatically added once if you purchase an annual policy, and is automatically added to each payment taken if you select the monthly recurring billing option. The quote given automatically includes this installment fee.</p>
          <p className='termsFooter'>
            Renters Insurance is underwritten by Markel American Insurance Company,
            Waukesha, WI. The advertised product is not available in AK and RI.
            Other program options are available for these states. Claims and coverage
            subject to policy, language, limits and exclusions.
          </p>
        </Modal.Body>
        <Modal.Footer className="modalFooter">
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </div>
    );
}


export default TermsConditions;
