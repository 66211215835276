// eslint-disable-next-line import/named
import { BillingInfoActionTypes } from './billingInfo.types';

// eslint-disable-next-line import/prefer-default-export
export const setFirstName = firstName => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_FIRST_NAME,
    payload: firstName
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setLastName = lastName => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_LAST_NAME,
    payload: lastName
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setPhone = phone => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_PHONE,
    payload: phone
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setEmail = email => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_EMAIL,
    payload: email
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setAddressLine1 = addressLine1 => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_ADDRESS_LINE_1,
    payload: addressLine1
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setAddressLine2 = addressLine2 => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_ADDRESS_LINE_2,
    payload: addressLine2
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setCity = city => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_CITY,
    payload: city
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setState = state => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_STATE,
    payload: state
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setZip = zip => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_ZIP,
    payload: zip
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setCountry = country => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_COUNTRY,
    payload: country
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setCreditCardNumber = creditCardNumber => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_CREDIT_CARD_NUMBER,
    payload: creditCardNumber
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setCvc = cvc => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_CVC,
    payload: cvc
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setExpMonth = expMonth => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_EXP_MONTH,
    payload: expMonth
  };
};

// eslint-disable-next-line import/prefer-default-export
export const setExpYear = expYear => {
  return {
    type: BillingInfoActionTypes.SET_BILLING_EXP_YEAR,
    payload: expYear
  };
};
