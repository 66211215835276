// eslint-disable-next-line import/prefer-default-export
export const ResidentInfoActionTypes = {
  SET_RESIDENT_UNIT_NUMBER: 'SET_RESIDENT_UNIT_NUMBER',
  SET_RESIDENT_START_DATE: 'SET_RESIDENT_START_DATE',
  SET_RESIDENT_FIRST_NAME: 'SET_RESIDENT_FIRST_NAME',
  SET_RESIDENT_LAST_NAME: 'SET_RESIDENT_LAST_NAME',
  SET_RESIDENT_EMAIL: 'SET_RESIDENT_EMAIL',
  SET_RESIDENT_PHONE: 'SET_RESIDENT_PHONE',
  SET_RESIDENT_OPT_IN: 'SET_RESIDENT_OPT_IN'
};
