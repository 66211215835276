import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import dayjs from 'dayjs';
import InputMask from 'react-input-mask';
import { useMemo, useState } from 'react';

import {
  Button,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import Label from './Label';

const SubmitButton = ({ onCampus, schoolID, loading }) => {
  if (onCampus)  {
    return (
      <Button type="submit" variant='primary' disabled={loading}>
        {loading ? 'Loading…' : 'Continue'}
      </Button>
    );
  }
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`https://gradguard.com/renters/enroll?school_id=${schoolID}&on_campus=false`}
    >
      <Button>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="external-link"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{width: 15, marginRight: 8}}
          >
            <path
              fill="currentColor"
              d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM474.67,0H316a28,28,0,0,0-28,28V46.71A28,28,0,0,0,316.79,73.9L384,72,135.06,319.09l-.06.06a24,24,0,0,0,0,33.94l23.94,23.85.06.06a24,24,0,0,0,33.91-.09L440,128l-1.88,67.22V196a28,28,0,0,0,28,28H484a28,28,0,0,0,28-28V37.33h0A37.33,37.33,0,0,0,474.67,0Z"
            />
          </svg>
          <span>
            Continue to GradGuard
          </span>
        </div>
      </Button>
    </a>
  )
}

const ResidentInfoForm = ({
  startDate,
  residentInfo,
  onSubmit,
  schoolID,
  loading,
  minStartDate,
  maxStartDate,
}) => {
  const [onCampus, setOnCampus] = useState(true);

  const schema = useMemo(() => yup.object({
    firstName: yup.string().trim().required().label('First name'),
    lastName: yup.string().trim().required().label('Last name'),
    email: yup.string().trim().email().required().label('Email'),
    phone: yup.string().trim().max(15).min(10).required().label('Phone'),
    startDate: yup.string().trim().required('Start date is required').test(
      'min-date',
      `Start date must be tomorrow or later ${minStartDate.format('MMM DD, YYYY')}.`,
      (value) => {
        const isValid = dayjs(value).isAfter(dayjs());
        return isValid;
      },
    ).test(
      'max-date',
      `Start date must be before or equals to ${maxStartDate.format('MMM DD, YYYY')}`,
      (value) => dayjs(value).isBefore(maxStartDate) || dayjs(value).isSame(maxStartDate),
    ).label('Start date')
  }).required(), [maxStartDate, minStartDate]);

  const minStartDateFormat = useMemo(() => minStartDate.format('YYYY-MM-DD'), [minStartDate]);
  const maxStartDateFormat =  useMemo(() => maxStartDate.format('YYYY-MM-DD'), [maxStartDate]);

  const { register, handleSubmit, formState: { errors }, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...residentInfo, startDate },
  });

  const residentsOrStudent = schoolID ? 'Student ' : "Resident's ";

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
    <Container className="styledContainer">
      <Row>
        <Col>
          <Logo/>
        </Col>
      </Row>
      <Container style={{maxWidth: '450px' }}>
        {schoolID &&
          <Row className='mb-2'>
            <Col>
              <Form.Check>
                <Label light inline>
                  <Form.Check.Input
                    name='onCampus'
                    onChange={() => setOnCampus(!onCampus)}
                    checked={onCampus}
                  />
                  I'm living on Campus
                </Label>
              </Form.Check>
            </Col>
          </Row>
        }
        <Row className='justify-content-center mb-2'>
          <Col xs={12} sm={6}>
            <Label>
              {residentsOrStudent} First Name&#x2a;
              <Form.Control
                type='text'
                className={errors.firstName && 'is-invalid'}
                {...register("firstName", { required: true })}
              />
              {errors.firstName && <span className="error">{errors.firstName.message}</span>}
            </Label>
          </Col>
          <Col xs={12} sm={6}>
            <Label>
              {residentsOrStudent} Last Name&#x2a;
              <Form.Control
                type='text'
                className={errors.lastName && 'is-invalid'}
                {...register("lastName", { required: true })}
              />
              {errors.lastName && <span className="error">{errors.lastName.message}</span>}
            </Label>
          </Col>
        </Row>
        <Row className='justify-content-center mb-2'>
          <Col xs={12} sm={6}>
            <Label>
              {residentsOrStudent}  Email&#x2a;
              <Form.Control
                className={errors.email && 'is-invalid'}
                {...register("email", { required: true, type: 'email' })}
              />
              {errors.email && <span className="error">{errors.email.message}</span>}
            </Label>
          </Col>
          <Col xs={12} sm={6}>
            <Label>
              {residentsOrStudent}  Phone*
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <InputMask
                    mask="999 999 9999"
                    maskChar={null}
                    value={field.value}
                    onChange={field.onChange}
                  >
                    {
                      (inputProps) => (
                        <Form.Control
                          className={errors.phone && 'is-invalid'}
                          label="Phone"
                          type="text"
                          ref={field.ref}
                          {...inputProps}
                        />
                      )
                    }
                  </InputMask>
                )}
              />
              {errors.phone && <span className="error">{errors.phone.message}</span>}
            </Label>
          </Col>
        </Row>
        <Row className='mb-2'>
          {!schoolID &&
            <Col xs={12} sm={6}>
              <Label>Unit Number</Label>
              <Form.Control
                type='text'
                {...register("unitNumber")}
              />
            </Col>
          }
          <Col xs={12} sm={6}>
            <Label>
              Coverage Start Date
              <Form.Control
                type='date'
                name='startDate'
                min={minStartDateFormat}
                max={maxStartDateFormat}
                className={errors.startDate && 'is-invalid'}
                {...register("startDate", { required: true, type: 'date' })}
              />
              {errors.startDate && <span className="error">{errors.startDate.message}</span>}
            </Label>
          </Col>
        </Row>
        <div><small> &#x2a; indicates a required field</small></div>
      </Container>
      </Container>
      <Container className="mt-4">
      <Row className='justify-content-center mb-2'>
        <Col className='text-center'>
          <Form.Check>
            <Label light inline>
              <Form.Check.Input
                name='optIn'
                {...register("optIn", { type: 'checkbox' })}
              />
              I&rsquo;d like to receive important information about my policy via text.
            </Label>
          </Form.Check>
        </Col>
      </Row>
      <Row className='justify-content-center mb-2'>
        <Col className='text-center'>
          <SubmitButton
            onCampus={onCampus}
            schoolID={schoolID}
            loading={loading}
          />
        </Col>
      </Row>
      <Row className='justify-content-center mb-2'>
        <Col className='text-center pt-3'>
          <Link to={schoolID ? `/schools/${schoolID}/quote` : '/purchase-flow/quote'} className='text-secondary'>
            Return to Previous Screen
          </Link>
        </Col>
      </Row>
    </Container>
  </form>
  );
};

export default ResidentInfoForm;