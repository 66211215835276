import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getPropertyQuote,
} from './API';
import PropTypes from 'prop-types';
import {
  setStates,
  setSelectedProperty
} from '../redux/properties/properties.actions';
import { setQuote } from '../redux/quote/quote.actions';
import WhatIsCovered from './WhatIsCovered';
import {
  Alert,
  Col,
  Container,
  Row
} from 'react-bootstrap';
import Modal from 'react-modal';
import {
  Link,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import CollegeRentersInsurance from './CollegeRentersInsurance';
import Wrapper from './Wrapper';
import Logo from './Logo';
import PopOutPanel from './PopOutPanel';
import PurchaseSuccess from './PurchaseSuccess';
import QuoteBox from './ui/QuoteBox';
import QuoteBoxLoading from './ui/QuoteBoxLoading';
import { useGlobalContext } from '../hooks/global/GlobalContext';
import useSchoolQuote from '../hooks/useSchoolQuote';

const Quote = ({
  selectedProperty,
  quote,
  setQuote,
  policy,
}) => {
  const {
    implementation: {
      getImplementation,
      coverage,
      loading: impLoading,
      startDate,
    },
    school: {
      getSchool,
      data: school,
      enableEarthquakeCoverage,
      earthquakeCoverage,
      setEarthquakeCoverage,
    },
  } = useGlobalContext();
  const { params } = useRouteMatch();
  const schoolID = params.schoolID; // optional
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { getQuote: getSchoolQuote } = useSchoolQuote();

  /** LOAD SCHOOL & IMPLEMENTATION */
  useEffect(() => {
    if (schoolID) {
      getImplementation(schoolID);
      getSchool(schoolID);
    }
  }, [getImplementation, getSchool, schoolID])


  /** PROPERTY QUOTE */
  useEffect(() => {
      const getQuote = async () => {
        const response = await getPropertyQuote(selectedProperty.id, earthquakeCoverage);
        if (response.status === 200) {
          setQuote(response.body.data);
        } else {
          setError(response.message);
        }
      };
      if (selectedProperty !== null && !schoolID) {
        getQuote();
      }
    },
    [selectedProperty, schoolID, earthquakeCoverage, setQuote]
  );

  /**
   * RENTERS SCHOOL QUOTE
   */
   useEffect(() => {
    const getQuote = async (schoolID) => {
      const response = await getSchoolQuote(schoolID, earthquakeCoverage, coverage, startDate);
      if (response.status === 200) {
        setQuote(response.body.data);
      } else {
        setError(response.message);
      }
    };

    if (school && !impLoading) {
      getQuote(schoolID);
    }
  }, [school, impLoading, earthquakeCoverage, coverage, setQuote, schoolID, startDate, getSchoolQuote]);


  if (!schoolID && !selectedProperty) {
    return ( <Redirect to='/purchase-flow' /> );
  }

  if(policy) {
    return (
      <PurchaseSuccess
        policy={policy}
      />
    );
  }

  return (
    <Wrapper>
       <Container className="styledContainer">
        <Logo/>
        <Row>
          <Col>
            <CollegeRentersInsurance />
          </Col>
          <Col>
            <PopOutPanel>
              {error !== null && (
                <Alert variant='danger'>{error}</Alert>
              )}
              {quote === null && error === null && (
                <QuoteBoxLoading enableEarthquakeCoverage={enableEarthquakeCoverage} />
              )}
              {quote !== null && (
                <QuoteBox
                  setShowModal={setShowModal}
                  quote={quote}
                  enableEarthquakeCoverage={enableEarthquakeCoverage}
                  earthquakeCoverage={earthquakeCoverage}
                  setEarthquakeCoverage={setEarthquakeCoverage}
                />
              )}
            </PopOutPanel>
            <Row className='pb-2 justify-content-center'>
              <Col className='text-center'>
                  <Link className='btn btn-primary' to={schoolID ? `/schools/${schoolID}/resident-info` : '/purchase-flow/resident-info'}>
                    Purchase Coverage
                  </Link>
              </Col>
            </Row>
            <Row className='pb-2 justify-content-center'>
              <Col className='pt-3 text-center'>
                <Link tabIndex='0'
                      to={schoolID ? `/schools/${schoolID}/decline` :'/purchase-flow/decline'}
                      className='text-secondary'>
                  Decline All Coverage
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
        </Container>
        <Container>
      </Container>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        ariaHideApp={false}
        shouldFocusAfterRender={true}
        shouldCloseOnEsc={true}
        style={{ content: {} }}
      >
          <button type="button" className="close" onClick={() => setShowModal(false)} onKeyPress={() => setShowModal(false)}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        <WhatIsCovered onClose={() => setShowModal(false)} />
      </Modal>
    </Wrapper>
  );
}

Quote.propTypes = {
  states: PropTypes.array,
  setStates: PropTypes.func.isRequired,
  selectedProperty: PropTypes.object,
  setSelectedProperty: PropTypes.func.isRequired,
  quote: PropTypes.object,
  setQuote: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  states: state.properties.states,
  selectedProperty: state.properties.selectedProperty,
  quote: state.quote.quote,
  policy: state.policy.policy,
});

const mapDispatchToProps = dispatch => ({
  setStates: states => dispatch(setStates(states)),
  setSelectedProperty: selectedProperty => dispatch(setSelectedProperty(selectedProperty)),
  setQuote: quote => dispatch(setQuote(quote)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Quote);