import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
const Mamoto = ({ children }) => {
  const instance = createInstance({
    urlBase: 'https://gradguard.matomo.cloud/',
    siteId: process.env.REACT_APP_MATOMO_SITE_ID,
  })
  return <MatomoProvider value={instance}>
    {children}
  </MatomoProvider>;
}

export default Mamoto;
