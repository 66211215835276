const Errors = ({ errors }) => {
  // Validation Error
  if (errors.error?.errors && typeof errors.error?.errors === 'object') {
    return Object.keys(errors.error?.errors).map(key => {
      if (Array.isArray(errors.error.errors[key])) {
        return errors.error.errors[key].map(error => (
          <div key={error}>{error}</div>
        ));
      }
      return errors[key];
    });
  }

  // Message Error
  if ( errors?.error?.error_message && typeof errors.error.error_message === 'string') {
    return errors.error.error_message;
  }

  if (typeof errors === 'string' || errors instanceof String) {
    return errors;
  }
  return `unknown error. ${(JSON.stringify(errors))}`;
}

export default Errors;
