
import styled from 'styled-components';

const Header = styled.h3`
  font-size: 1.5rem;
  color: #19477B;
`;

const Text = styled.div`
  font-size: 0.875rem;
  color: #000;
  margin-bottom: 20px;
`;



export default function CollegeRentersInsurance() {
  return (
    <>
      <Header>College Renters Insurance</Header>
      <Text>
      Offered at over 400 colleges and universities, GradGuard's programs
      are specifically designed for college aged students and do not
      require a credit score to purchase. Here are just some of the
      benefits you're eligible for with the GradGuard College Renters Protection Program:
      </Text>
      <Text>
        <ul>
            <li>Worldwide personal property coverage</li>
            <li>Protection from theft, fire, water damage, vandalism, and more</li>
            <li>Lower deductibles (average is $100)</li>
            <li>Replacement cost coverage</li>
            <li>Electronics covered up to the policy limits</li>
        </ul>
      </Text>
    </>
  )
}
