import {
  Col,
  Container,
  Row
} from 'react-bootstrap';
import Wrapper from './Wrapper';
import Logo from './Logo';

const PurchaseSuccess = ({ policy }) => {
  return (
    <Wrapper>
      <Container className="styledContainer">
        <Row>
          <Col className="centered">
            <Logo style={{maxWidth: '140px'}}/>
            <h3 className='card-heading my-3'>GradGuard Renters Insurance</h3>
            <h3 className='card-heading my-3'>Thank you for purchasing college renters insurance.</h3>
            <p>Policy Number: <span style={{color: '#264572'}}>{policy.policy_number}</span></p>
            <div className="centered" style={{    fontSize: 16, padding: 20}}>Be on the lookout for a confirmation email from GradGuard that includes more information. Make sure to add GradGuard.com to your email contacts to ensure delivery.</div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default PurchaseSuccess;
