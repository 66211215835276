import { useMemo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Alert from 'react-bootstrap/Alert';
import { formatPrice } from '../../resources/utils';

const Header = styled.h3`
  font-size: 1.5rem;
  color: #19477B;
`;

const Text = styled.div`
  font-size: 0.875rem;
  color: #000;
  margin-bottom: 20px;
`;



export default function QuoteNewPriceMessage({
  startDate,
  newStartDate,
  price,
  newPrice,
}) {
  const sd = useMemo(() => dayjs(startDate).format('MMM D, YYYY'), [startDate]);
  const nsd = useMemo(() => dayjs(newStartDate).format('MMM D, YYYY'), [newStartDate]);
  return (
    <>
      <Header>Price Updated</Header>
      <Text>
        Your quote has changes. I'ts because the "Coverage Start Date" changed.
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '20px',
        }}>
          <Alert variant='primary'>
            {sd}
          </Alert>
          <div style={{marginBottom: '1rem'}}>
            TO
          </div>
          <Alert variant='primary'>
            {nsd}
          </Alert>
        </div>
      </Text>
      <Text>
        Therefore the quote has a new price:
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '20px',
        }}>
          <Alert variant='success'>
            {formatPrice(price)}
          </Alert>
          <div style={{marginBottom: '1rem'}}>
            TO
          </div>
          <Alert variant='success'>
            {formatPrice(newPrice)}
          </Alert>
        </div>
      </Text>
    </>
  )
}
