import { useCallback, useMemo, useState } from "react";
import dayjs from 'dayjs';

import {
  getCurrentRentersImplementationBySchoolID,
} from '../api/getSchoolQuote';

const tomorrow = dayjs().add(1, 'd');
const todayPlus270 = dayjs().add(270, 'd');
const useImplementation = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [startDate, setStartDate] = useState(tomorrow.format('YYYY-MM-DD'));
    const [maxStartDate, setMaxStartDate] = useState(todayPlus270);
    const minStartDate = useMemo(() => tomorrow, []);
    const [coverage, setCoverage] = useState({
      liability:100000,
      property:5000,
      deductible: 100,
    });

    const setQuoteValues = (implementation) => {
      setCoverage((state) => ({
        ...state,
        liability: implementation.default_liability_coverage ?? state.liability,
        property: implementation.default_property_coverage ?? state.property,
      }))
    };

    const getImplementation = useCallback(async (schoolID) => {
      setLoading(true);
      const implementation = await getCurrentRentersImplementationBySchoolID(schoolID);
      if (implementation.status === 200) {
        setQuoteValues(implementation.body.data);
        setData(implementation.body.data);
        if (implementation.body.data.start_date) {
          const newStartDate = dayjs(implementation.body.data.start_date);
          // if implementation start date is after today
          if (newStartDate.isAfter(dayjs(), 'date')) {
            setStartDate(implementation.body.data.start_date);
            if (newStartDate.isAfter(todayPlus270)) {
              setMaxStartDate(newStartDate);
            }
          }
        }
      } else {
        // use default values
      }
      setLoading(false);
    },[])

    return {
      getImplementation,
      data,
      loading,
      coverage,
      setQuoteValues,
      startDate,
      setStartDate,
      maxStartDate,
      minStartDate,
    };
}

export default useImplementation;
