import {
  Button,
  Row,
  Col,
  Modal,
} from 'react-bootstrap';
import Logo from './Logo';
import checkmark from '../assets/check-on.svg';
import styled from 'styled-components';

const Header = styled.h3`
  font-size: 24px;
  color: #19477B;
`;

const Subheader = styled.h4`
  font-size: 20px;
  color: #19477B;
  margin-top: 30px;
`;


const Text = styled.p`
  font-size: 12px;
  color: #000;
`;

const Check = styled.span`
  margin-right: 10px;
  padding-left: 18px;
  height: 22px;
  background-image: url(${checkmark});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const CheckLabel = styled(Col)`
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 10px;
`;

const WhatIsCovered = ({ onClose }) => {
    return (
        <div id="WhatIsCovered">
          <Modal.Title className="modal-header">
            <div className="modalTitle">
              <Logo style={{maxWidth: '140px'}}/>
            </div>
          </Modal.Title>
        <Modal.Body>
           <Header>What coverages are provided under a GradGuard Renters Insurance Policy?</Header>
           <Text>With GradGuard’s Renters Insurance, you will receive coverage for personal property and personal liability. Your personal property is important and that’s why we’ll cover it anywhere in the world, including items in storage. Here are some examples of personal property covered under our policy:</Text>
          <Row>
            <CheckLabel xs={6} md={6}>
              <Check src="../assets/check-on.svg"/>Personal Computer
            </CheckLabel>
            <CheckLabel xs={6} md={6}>
              <Check src="../assets/check-on.svg"/>Bicycle
            </CheckLabel>
            <CheckLabel xs={6} md={6}>
              <Check src="../assets/check-on.svg"/>TV or Stereo System
            </CheckLabel>
            <CheckLabel xs={6} md={6}>
              <Check src="../assets/check-on.svg"/>Clothing
            </CheckLabel>
            <CheckLabel xs={6} md={6}>
              <Check src="../assets/check-on.svg"/>Musical Instruments
            </CheckLabel>
            <CheckLabel xs={6} md={6}>
              <Check src="../assets/check-on.svg"/>Books and Supplies
            </CheckLabel>
          </Row>
          <Subheader>
            Covered Perils (causes of loss):
          </Subheader>
          <Text>Vandalism, Theft, Fire, Lightning, Windstorm, Hail, Smoke, Flood (where available), Breakage of Glass, and Falling Objects.</Text>

          <Subheader>
            What is liability coverage in the context of insurance?
          </Subheader>
          <Text>
            Liability coverage protects you financially for unintentional damage to your covered residence or bodily injury when you are found legally liable, within your residence premises. Below are some examples.
          </Text>
          <Text>
            <ul>
              <li>During a visit, your friend accidentally trips over your longboard that you left in the middle of your room and breaks an arm.</li>
              <li>You unintentionally set off the sprinkler system in your residence hall, causing the floor beneath you to flood.</li>
            </ul>
          </Text>

          <Subheader>
            What are some things that are excluded under the policy?
          </Subheader>
          <Text>
              PERSONAL PROPERTY GENERAL EXCLUSIONS*:
          </Text>
          <Text>
              <ul>
                <li>
                  Unexplained or mysterious disappearance (i.e. my phone fell out of my pocket, now I can’t find it)
                </li>
                <li>
                  Illegal, unlawful or intentional acts of an insured or a guest of an insured (i.e. you harm someone on purpose)
                </li>
                <li>
                  Bed Bugs
                </li>
                <li>
                  Neglect to use all reasonable means to save and preserve covered property at and after the time of loss, or when Property is endangered by a covered peril
                </li>
              </ul>
          </Text>
          <Text>
            PERSONAL LIABILITY GENERAL EXCLUSIONS*:
          </Text>
          <Text>
            <ul>
              <li>
                Resulting from any act or omission that is intended by an insured to cause any harm or that any insured could reasonably expect to cause harm or preformed at the direction of any insured
              </li>
              <li>
                Arising out of the rental or holding for rental of any residence premises owned by you (i.e. if you are renting any of your belongings out, there’s no coverage)
              </li>
              <li>
                Arising out of the insured’s knowingly permitting or failing to take action to prevent the illegal consumption of alcoholic beverages by an underage person
              </li>
            </ul>
            <i>*The list provided is based off of the most commonly asked questions. Please refer to the full list of exclusions for both property and liability coverage for the remainder</i>
          </Text>
          <Subheader>
            What is a deductible?
          </Subheader>
          <Text>
            A deductible represents the amount of any covered claim that is your responsibility to pay.  For example, your two-year-old laptop is stolen, GradGuard’s program will reimburse for this year’s make of your laptop, less the deductible: $800 (cost of laptop) - $100 (deductible) = $700 claim payment
          </Text>

          <Subheader>
            Does my GradGuard Renters Insurance policy terminate once I leave my apartment?
          </Subheader>
          <Text>No, GradGuard is a separate entity from the university/property manager and as such does not have access to their records.  If you would like to make changes or cancel your policy, you need to visit your GradGuard online account at <a href={"https://account.gradguard.com"}>account.gradguard.com</a> or give us a call at <a href={"tel:8669857598"}>(866) 985-7598</a>.</Text>

          <Subheader>
            Can I make policy changes online or do I need to speak to a representative?
          </Subheader>
          <Text>You will want to visit your online account at <a href={"https://account.gradguard.com"}>account.gradguard.com</a> to make any changes to your current policy. If you are unable to make the change that you need to, or are having trouble doing so, please call and speak with an agent at <a href={"tel:8669857598"}>(866) 985-7598</a> for further assistance.</Text>
            <Text>
              <i><strong>* Please Note:</strong> This document contains some helpful summary information about your policy.  It does not form a part of your policy and it cannot be relied on as a description of the coverage provided.  Please refer to the policy for its full terms, conditions and exclusions.</i>
            </Text>
        </Modal.Body>
        <Modal.Footer className="modalFooter">
          <Button variant="primary"  onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
        </div>
    )
}

export default WhatIsCovered;