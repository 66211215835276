import axios from 'axios';
import stateAbbreviations from '../resources/stateAbbreviations';
import { v4 as uuidV4 } from 'uuid';
import Bugsnag from '@bugsnag/js';
import headers from '../api/headers';

const unrecoverableErrorMessage = 'We’re sorry, there was a problem submitting your request. Please call (866) 985-7598 for assistance.'

export const getStatesProperties = async () => {
  const url = new URL(`${process.env.REACT_APP_GRAD_GUARD_API_BASE_URL}/properties`);
  url.searchParams.set('property_group', 'American Campus Communities');
  try {
    const response = await axios(
      url.toString(),
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_GRAD_GUARD_API_TOKEN}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const status = response.status;
    if (status === 200) {
      const responseBody = response.data;
      let s = [];
      for (let abbreviation in stateAbbreviations) {
        const properties = responseBody.data.filter(property => property.address_state === abbreviation);
        if (properties.length > 0) {
          s.push({
            name: stateAbbreviations[abbreviation],
            abbreviation: abbreviation,
            properties: properties
          });
        }
      }
      return {
        status: status,
        body: s
      };
    } else {
      return {
        status: status,
        message: unrecoverableErrorMessage
      };
    }

  } catch (error) {
    Bugsnag.notify(error, (report) => {
      report.metadata = { 'extra': { 'place': 'getStatesProperties' } };
    });
    return {
      status: null,
      message: unrecoverableErrorMessage
    }
  }
};

export const ajax = async (api) => {
  try {
    const response = await api();
    return {
      status: response.status,
      body: response.data,
    };
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      if ([200, 201, 422].indexOf(status) > -1) {
        return {
          status: status,
          body: error.response.data,
        };
      }
    }
    Bugsnag.notify(error);
    return {
      status: null,
      message: unrecoverableErrorMessage
    }
  }
}

export const getPropertyQuote = async (
  propertyId,
  enableEarthquakeCoverage
) => {
  let url = new URL(`${process.env.REACT_APP_GRAD_GUARD_API_BASE_URL}/renters/quote`);
  url.searchParams.set('property_id', propertyId);
  url.searchParams.set('preferred', true);
  if (enableEarthquakeCoverage === true) {
    url.searchParams.set('earthquake_coverage', true);
  }
  try {
    const response = await axios(
      url.toString(),
      {headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_GRAD_GUARD_API_TOKEN}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }}
    );

    const status = response.status;
    if (status !== 200) {
      return {
        status: status,
        message: unrecoverableErrorMessage
      };
    }

    return {
      status: status,
      body: response.data,
    };
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      if ([200, 201, 422].indexOf(status) > -1) {
        return {
          status: status,
          body: error.response.data,
        };
      }
    }
    Bugsnag.notify(error, (report) => {
      report.metadata = { 'extra': { 'place': 'getPropertyQuote' } };
    });
    return {
      status: null,
      message: unrecoverableErrorMessage
    }
  }
}

export const makePurchase = async (
  billingInfo,
  property,
  residentInfo,
  quote,
  isSchool,
  isBML,
  earthquakeCoverage,
  startDate,
) => {
  const requestBody = {
    user_first_name: billingInfo.firstName,
    user_last_name: billingInfo.lastName,
    user_email: billingInfo.email,
    user_phone: billingInfo.phone ? billingInfo.phone.replace(/\s/g, '') : '',
    user_sms_opt_in: isBML,
    user_address1: billingInfo.addressLine1,
    user_address2: billingInfo.addressLine2,
    user_address_city: billingInfo.city,
    user_address_state: billingInfo.state,
    user_address_zip: billingInfo.zip,
    user_address_country: billingInfo.country,
    cc_first_name: billingInfo.firstName,
    cc_last_name: billingInfo.lastName,
    cc_address1: billingInfo.addressLine1,
    cc_address_city: billingInfo.city,
    cc_address_state: billingInfo.state,
    cc_address_zip: billingInfo.zip,
    cc_address_country: billingInfo.country,
    cc_account_number: billingInfo.creditCardNumber,
    cc_exp_month: billingInfo.expMonth,
    cc_exp_year: billingInfo.expYear,
    insured_first_name: residentInfo.firstName,
    insured_last_name: residentInfo.lastName,
    insured_email: residentInfo.email,
    insured_phone: residentInfo.phone ? residentInfo.phone.replace(/\s/g, '') : '',
    insured_sms_opt_in: residentInfo.optIn,
    insured_address1: property.address1,
    insured_address2: residentInfo.unitNumber ?? undefined,
    insured_address_city: property.address_city,
    insured_address_state: property.address_state,
    insured_address_zip: property.address_zip,
    insured_address_country: property.address_country,
    ref_code: 'acc',
    tracking_codes: 'occ,acc,renters_widget',
    school_id: isSchool ? property.id : undefined,
    property_id: !isSchool ? property.id : undefined,
    on_campus: 'true',
    deductible: quote.quote.deductible,
    total_payments: quote.quote.term === 1 ? -1 : 1,
    payment_interval: quote.quote.term,
    property_coverage: quote.quote.property_limit,
    liability_coverage: quote.quote.liability_limit,
    earthquake_coverage: earthquakeCoverage ? quote.quote.earthquake_limit : 0,
    preferred: true,
    start_date: startDate,
    purchase_request_id: uuidV4(),
    purchase_request_source: 'widget'
  }
  const url = `${process.env.REACT_APP_GRAD_GUARD_API_BASE_URL}/renters/policy`;
  return ajax(() => {
    return axios(
      url,
      {
        method: 'POST',
        headers,
        data: JSON.stringify(requestBody)
      }
    );
  })
};

export const makeDecline = async (
  firstName,
  lastName,
  email,
  propertyId,
  schoolId,
  declineReason,
  insuranceProvider,
) => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  const startDate = date.toISOString().slice(0, 10);

  const requestBody = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    property_id: propertyId,
    school_id: schoolId,
    status_reason: declineReason === 'have_coverage'
      ? `${declineReason}: ${insuranceProvider}`
      : declineReason
    ,
    ref_code: 'acc',
    source: 'acc',
    start_date: startDate
  };

  let url = new URL(`${process.env.REACT_APP_GRAD_GUARD_API_BASE_URL}/renters/decline`);
  return ajax(() => {
    return axios(
      url.toString(),
      {
        method: 'POST',
        headers,
        data: JSON.stringify(requestBody)
      }
    );
  })
};
