import {
  Col,
  Container,
  Row
} from 'react-bootstrap';
import Wrapper from './Wrapper';
import Logo from './Logo';
import "./theme/theme.css"

const DeclineSuccess = () => {
  return (

  <Wrapper>
    <Container className="styledContainer">
      <Row>
        <Col className="centered">
          <Logo style={{maxWidth: '140px'}}/>
          <h3 className='card-heading my-3'>GradGuard Renters Insurance</h3>
          <h3 className='card-heading my-3'>No Problem!</h3>
          <div className="centered" style={{ fontSize: 16, padding: 20}}>If you change your mind, you can always find us again by visiting <a href={"https://www.gradguard.com"}>www.gradguard.com</a></div>
        </Col>
      </Row>
    </Container>
  </Wrapper>

  );
}

export default DeclineSuccess;
