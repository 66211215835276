import useImplementation from '../useImplementation';
import useSchool from '../useSchool';

const useGlobal = () => {
  const implementation = useImplementation();
  const school = useSchool();
  return {
    implementation,
    school,
  };
};

export default useGlobal;
